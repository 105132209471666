import gql from 'graphql-tag';

export default gql`
query getTeamRoles {
  roles(order_by: {level: asc}, where: {_or: [{name: {_eq: "Publisher"}}, {name: {_eq: "Editor"}}, {name: {_eq: "Auditor"}}]}) {
    role_id
    name
    handle
    description
  }
}
`;
