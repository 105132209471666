<template>
  <div>
    <b-modal
        id="modal-edit-team-user-role"
        ref="modal-edit-team-user-role"
        footer-class="flex-nowrap"
        no-close-on-backdrop
        no-close-on-esc
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.editUser.title') }}</h3>
      </template>

      <template #default>

        <div class="mx-5 mb-4" v-if="teamRoles.length">
          <div class="mt-3 text-center">
            <p><strong>
              {{ $t('modals.editUser.name') }}: {{ userObject.name || '-----' }}<br>
              {{ $t('modals.editUser.email') }}: {{ userObject.email }}
            </strong></p>
          </div>
          <b-dropdown
            id="setRole"
            menu-class="w-100 pb-2"
            block
            :text="dropdownText"
            v-model="role"
          >
            <div class="w-100" v-for="(r, index) in teamRoles" :key="index">
              <b-dropdown-item-button  @click="setRole(r)">
                {{ r.name }}</b-dropdown-item-button>
              <b-dropdown-text>
                {{ $t(`roles.${r.handle}`) }}</b-dropdown-text>
              <b-dropdown-divider
                v-if="index !== teamRoles.length-1">
              </b-dropdown-divider>
            </div>
          </b-dropdown>
        </div>
      </template>

      <template #modal-footer>
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="cancelModal"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button

            variant="primary"
            class="p-3"
            block
            @click="editUser"
        >
          {{ $t('modals.editUser.validate') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AddTeamMember from '@/graphQlQueries/mutations/addTeamMember';
import GetTeamRoles from '@/graphQlQueries/queries/getTeamRoles';

export default {
  name: 'ModalEditUserRole',
  props: [
    'teamId',
    'userObject',
  ],
  data() {
    return {
      dropdownText: null,
      teamRoles: [],
      role: null,
    };
  },
  mounted() {
    this.role = this.userObject.role;
    this.dropdownText = this.role.name;
    this.$refs['modal-edit-team-user-role'].show();
  },
  apollo: {
    teamRoles: {
      query: GetTeamRoles,
      update(data) {
        const roles = [];
        data.roles.forEach((item) => {
          const element = {
            role_id: item.role_id,
            name: item.name,
            handle: item.handle,
            description: item.description,
          };
          roles.push(element);
        });
        // sort the roles by role_id
        roles.sort((a, b) => {
          if (a.role_id < b.role_id) {
            return -1;
          }
          if (a.role_id > b.role_id) {
            return 1;
          }
          return 0;
        });
        return roles;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    updateFields(key, value) {
      this[key] = value;
    },
    async editUser() {
      try {
        await this.$apollo.mutate({
          mutation: AddTeamMember,
          variables: {
            obj: {
              userToAddId: this.userObject.userId,
              roleId: this.role.role_id,
              teamId: this.teamId,
            },
          },
        });
      } catch (ex) {
        console.error(ex);
      }
      this.name = null;
      this.role = null;
      this.$emit('editUser');
    },
    setRole(value) {
      this.dropdownText = value.name;
      this.role = value;
    },
    cancelModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style scoped>

</style>
